<template>
  <v-row no-gutters justify="center">
    <v-col cols="6">
      <user-projectinfo/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    "user-projectinfo": () => import('@/site/modules/core/user-projectinfo'),
  },
  created() {
    // console.log('===!home!===', this.$route.params)
    if (this.$route.params.project){
      let prj = this.$store.state.user.projects.find(obj => { return obj.name_sys === this.$route.params.project })
      console.log('======= prj ========', prj)
      this.$bus.emit('setProject', prj, '/home');
    }
  },
}
</script>
